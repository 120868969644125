<template>
  <div :class="Dark.isActive ? 'dark' : ''">
    <div class="q-gutter-sm">
      <div v-for="contrib in contributionTypesPresent" :key="contrib.value">
        <q-badge
          v-if="
            articleForm.contributions.filter((c) => c.type === contrib.value)
              .length > 0
          "
          color="primary"
          :label="
            contrib.label +
            ' (' +
            articleForm.contributions.filter((c) => c.type === contrib.value)
              .length +
            ')'
          "
        />
        <Contribution
          v-for="c in articleForm.contributions.filter(
            (c) => c.type === contrib.value
          )"
          :key="c.id"
          :disable="contributionsIsLocked"
          :editable="c.id != 0"
          :model-value="c"
          :publication="articleForm.publication"
          :article-id="articleForm.id"
          @remove-contribution="onRemove"
        />
      </div>
      <q-btn
        color="primary"
        :disable="contributionsIsLocked"
        :label="$t('articleContribution.labels.btnAdd')"
        icon="add"
        align="between"
      >
        <q-menu>
          <q-list>
            <q-item
              v-for="contrib in contributionTypes"
              :key="contrib"
              clickable
              @click="onAdd(contrib)"
              >{{ $t(`contributionType.${contrib}`) }}</q-item
            >
          </q-list>
        </q-menu>
      </q-btn>
      <q-separator class="q-mt-none" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { Dark } from "quasar";
import { storeToRefs } from "pinia";
import { ContributionState, ContributionType } from "@/types/api";
import { useCurrentArticleStore } from "@/stores/currentArticle";
import { i18n } from "@/i18n";
import Contribution from "@/components/Article/Contribution/Contribution.vue";
import { computed } from "vue";
import { sortContributionTypeMapping } from "@/components/Article/constant";
const {
  currentArticle: articleForm,
  contributionsIsLocked,
  lang,
} = storeToRefs(useCurrentArticleStore());

const contributionTypes = Object.values(ContributionType).filter(
  (v) => v !== ContributionType.Unknown
);

/** Ajout d'une contribution d'un type précis. */
function onAdd(type: ContributionType) {
  articleForm.value.contributions.push({
    id: 0,
    idContributor: "",
    type: type,
    idLanguage: lang.value,
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
    state: ContributionState.Unknown,
    amount: type === ContributionType.Freelancer ? 0 : undefined,
  });
}

/** Suppression d'une contribution. */
function onRemove(contribution) {
  var index = articleForm.value.contributions.indexOf(contribution);
  articleForm.value.contributions.splice(index, 1);
}

/**
 * Liste des types de contribution présents dans l'article, trie dans l'order de ContributionType.
 */
const contributionTypesPresent = computed(() => {
  let distinctContributionTypes = articleForm.value.contributions.reduce(
    (acc, curr) => {
      if (!acc[curr.type]) {
        acc[curr.type] = true;
      }
      return acc;
    },
    {}
  );

  return Object.keys(distinctContributionTypes)
    .map((c) => ({
      value: c,
      label: i18n.t(`contributionType.${c}`),
    }))
    .sort((a, b) => {
      return (
        (sortContributionTypeMapping[a.value] ?? 0) -
        (sortContributionTypeMapping[b.value] ?? 0)
      );
    });
});
</script>
