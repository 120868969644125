<template>
  <q-field
    v-if="articleContent.id"
    :loading="stateChanging"
    :disable="disable"
    dense
    for="inputArticleContentStatus"
    color="teal"
    filled
  >
    <template v-if="splitMode" #prepend>
      <q-btn class="q-mr-xs" dense flat @click="toggleLanguage">
        <img
          :alt="articleLanguage"
          class="img-flag"
          :src="getFlagPath(articleLanguage)"
        />
      </q-btn>
    </template>
    <template #control>
      <q-chip
        class="self-center"
        dense
        square
        :style="`background: ${getWorkflowStateColor(
          articleContent.state
        )}; color: ${getWorkflowStateTextColor(articleContent.state)};`"
      >
        {{ $t(`article.states.${articleContent.state}`) }}
      </q-chip>
    </template>
    <template #append>
      <q-btn id="inputArticleContentStatus" round dense flat icon="expand_more">
        <MenuTriggers
          :article="article"
          :article-content="articleContent"
          :disable="disable"
          @loading="(loading) => (stateChanging = loading)"
          @update:state="onUpdateState"
        />
      </q-btn>
    </template>
  </q-field>
</template>

<script setup lang="ts">
import type {
  ArticleContentDto,
  ArticleDto,
  WorkflowFireResult,
} from "@/types/api";
import { inject, onMounted, onUnmounted, ref } from "vue";
import MenuTriggers from "@/components/Article/MenuTriggers.vue";
import {
  getWorkflowStateColor,
  getWorkflowStateTextColor,
} from "@/helpers/workflowState";
import { EventBus } from "quasar";
import { EnglishLanguage, FrenchLanguage, getFlagPath } from "@/constants";
import { useCurrentArticleStore } from "@/stores/currentArticle";
import { storeToRefs } from "pinia";

const currentArticleStore = useCurrentArticleStore();
const { lang: articleLanguage } = storeToRefs(currentArticleStore);
const bus = inject<EventBus>("bus");
const stateChanging = ref<boolean>(false);

const splitMode = ref<boolean>(false);

bus?.on("splitMode", (value: boolean) => {
  splitMode.value = value;
});

defineProps<{
  articleContent: ArticleContentDto;
  article: ArticleDto;
  disable?: boolean;
}>();
const emits = defineEmits<{
  (e: "update:state", value: WorkflowFireResult): void;
}>();

onMounted(async () => {
  if (!bus) return;
  bus.on("FormArticle.splitMode", (value: boolean) => {
    splitMode.value = value;
  });
});
onUnmounted(() => {
  if (!bus) return;
  bus.off("FormArticle.splitMode");
});
/**
 *
 */
function onUpdateState(stateUpdated: WorkflowFireResult) {
  emits("update:state", stateUpdated);
}
/**
 * Toggle la langue de l'article
 */
function toggleLanguage() {
  articleLanguage.value =
    articleLanguage.value === FrenchLanguage ? EnglishLanguage : FrenchLanguage;
}
</script>
