import type { App } from "vue";
import type { Router } from "vue-router";
import type { Pinia } from "pinia";
import * as Sentry from "@sentry/vue";
import { createSentryPiniaPlugin } from "@sentry/vue";

declare const __SENTRY_RELEASE__: string;

/**
 * Initialize Sentry
 */
export default function initSentry(app: App, router: Router, pinia: Pinia) {
  if (import.meta.env.MODE === "development") return;

  Sentry.init({
    app,
    environment: import.meta.env.MODE,
    dsn: "https://5ed3f943416e2ed46fa9bfafbad760d6@erreport.indigo-net.com/7",
    release: __SENTRY_RELEASE__,
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration(),
    ],
  });
  pinia.use(
    createSentryPiniaPlugin({
      stateTransformer(state) {
        if (
          state.countries ||
          state.formats ||
          state.publications ||
          state.sections ||
          state.languages ||
          state.usersLight
        )
          return {};
        return state;
      },
    })
  );
}
