import { defineStore } from "pinia";
import { useLocalStorage } from "@vueuse/core";
import { spellCheckingService } from "@/services/SpellCheckingService";
import { conf_api_version_compatibility } from "@/constants";

const GRID_ARTICLE_VERSION = "1.1.1";
const APP_VERSION = "1.21";

export const useConfigStore = defineStore({
  id: "config",
  state: () => ({
    config: useLocalStorage(
      "config",
      {
        darkMode: false,
        locale: "fr" as "fr" | "en",
        publication: "",
        gridArticleVersion: "0.0.1",
        appVersion: "0.0.1",
        lastUpdate: new Date(2022, 1, 1),
        proLexisApiKey: "",
        apiVersion: conf_api_version_compatibility,
      },
      {
        listenToStorageChanges: false,
      }
    ),
  }),
  getters: {
    keyGridArticleSettings() {
      return "grid-articles";
    },
    keyCache() {
      return "axios-cache";
    },
    localeIsFr(state) {
      return state.config.locale === "fr";
    },
    showWhatsNew(state) {
      const lastUpdate =
        typeof state.config.lastUpdate === "string"
          ? new Date(state.config.lastUpdate)
          : state.config.lastUpdate;

      // retoune true quand state.config.lastUpdate a moins de 72h
      const diff = new Date().getTime() - lastUpdate.getTime();
      const diffHours = diff / (1000 * 60 * 60);
      return diffHours < 72;
    },
  },
  actions: {
    initialize() {
      if (this.config.gridArticleVersion !== GRID_ARTICLE_VERSION) {
        this.resetListArticleSettings();
        this.config.gridArticleVersion = GRID_ARTICLE_VERSION;
      }
      if (this.config.appVersion !== APP_VERSION) {
        this.clearCache(false);
        this.config.appVersion = APP_VERSION;
        this.config.lastUpdate = new Date();
      }
      this.config.apiVersion = conf_api_version_compatibility;
    },
    toggleDarkMode(state: boolean) {
      this.config.darkMode = state;
    },
    setLocale(locale: "fr" | "en") {
      this.config.locale = locale;
    },
    setPublication(publication: string) {
      this.config.publication = publication;
    },
    resetListArticleSettings() {
      for (const key in localStorage) {
        if (key.startsWith(this.keyGridArticleSettings)) {
          localStorage.removeItem(key);
        }
      }
    },
    clearCache(reload = true) {
      for (const key in localStorage) {
        if (key.startsWith(this.keyCache)) {
          localStorage.removeItem(key);
        }
      }
      if (reload) window.location.reload();
    },
    async fetchSpellCheckingAPIKey() {
      this.config.proLexisApiKey = await spellCheckingService.getApiKey();
    },
  },
});
