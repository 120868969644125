<template>
  <div v-if="!loading" style="height: 100%; width: 100%">
    <Layout v-if="$route.name !== RoutesName.Preview">
      <template #navigation>
        <Navigation />
      </template>
      <router-view></router-view>
    </Layout>
    <div v-else>
      <RouterView />
    </div>
  </div>
  <ee show :duration="0" />
</template>

<script setup lang="ts">
import { useQuasar, QSpinnerCube, Dark } from "quasar";
import { ref, defineAsyncComponent, watch } from "vue";
import langEn from "quasar/lang/en-GB.js";
import langFr from "quasar/lang/fr.js";
import Layout from "@/Layout.vue";
import Navigation from "@/components/Navigation.vue";
import { i18n } from "@/i18n";
import { handlePromiseResults, useLocale } from "@/utils";
import { configTheme } from "@/theme";

// Stores
import { useAccountStore } from "@/stores/account";
import { useCountryStore } from "@/stores/country";
import { useFormatStore } from "@/stores/format";
import { useLanguageStore } from "@/stores/language";
import { useUserStore } from "@/stores/user";
import { usePublicationStore } from "@/stores/publication";
import { useSectionStore } from "@/stores/section";
import { useConfigStore } from "@/stores/config";
import { useArticleSettingStore } from "@/stores/articleSetting";
import { useArticleCartStore } from "@/stores/articleCart";
import { signalRService } from "@/services/SignalrService";
import { conf_front_only, changeEnglishFlag } from "@/constants";
import { RoutesName } from "@/router/routesName";
import { ElcanoNotificationType, PreviewResult } from "./types/api";
import { useCurrentArticleStore } from "@/stores/currentArticle";
import { storeToRefs } from "pinia";

changeEnglishFlag();

const $q = useQuasar();
const loading = ref<boolean>(true);
const accountStore = useAccountStore();
const countryStore = useCountryStore();
const formatStore = useFormatStore();
const languageStore = useLanguageStore();
const userStore = useUserStore();
const publicationStore = usePublicationStore();
const sectionStore = useSectionStore();
const configStore = useConfigStore();
const articleSettingStore = useArticleSettingStore();
const { isFr } = useLocale();
const ee = defineAsyncComponent(() => import("@/components/Ee/ee.vue"));
useArticleCartStore();
const { currentArticle } = storeToRefs(useCurrentArticleStore());

configStore.initialize();
i18n.locale.value = configStore.config.locale;
$q.lang.set(isFr.value ? langFr : langEn);

$q.loading.show({
  spinner: QSpinnerCube,
  spinnerColor: "yellow",
  spinnerSize: 140,
  backgroundColor: "indigo",
  message: i18n.t("messages.loading"),
  messageColor: "black",
});
let promises: (Promise<void | unknown> | void)[] = [];
if (conf_front_only) {
  promises.push(signalRService.setup());
} else {
  promises.push(
    accountStore.fetchUserProfile(),
    accountStore.fetchAssignments(),
    countryStore.fetchCountries(),
    formatStore.fetchFormats(),
    languageStore.fetchLanguages(),
    publicationStore.fetchPublications(),
    sectionStore.fetchSections(),
    articleSettingStore.fetchArticleSettings(),
    articleSettingStore.fetchYearsLockedList(),
    userStore.fetchUsersLight(),
    signalRService.setup(),
    configStore.fetchSpellCheckingAPIKey()
  );
}

loadAppData();

Dark.set(configStore.$state.config.darkMode);
configTheme(configStore.$state.config.publication);

watch(
  () => configStore.$state.config.publication,
  (newValue) => configTheme(newValue)
);

/**
 * Chargement des stores nécessaires au fonctionnement de l'application
 */
async function loadAppData() {
  const results = await Promise.allSettled(promises);
  try {
    handlePromiseResults(results);
    $q.loading.hide();
    loading.value = false;
    subscribePreviewEnd();
  } catch (err) {
    for (const error of (err as AggregateError).errors) {
      console.error(error);
    }
  }
}

/**
 * S'inscrit au canal général pour le type PreviewEnd
 */
function subscribePreviewEnd() {
  signalRService.subscribeToMessageType(
    "_GENERAL_",
    ElcanoNotificationType.PreviewEnd,
    (data) => handlePreviewEnd(data.data)
  );
}

/**
 * Ouvre la préview pour l'article importé
 */
function handlePreviewEnd(data: PreviewResult) {
  if (data.success) {
    window.open(data.url, "_blank");
  } else if (
    !currentArticle.value.contents.some((c) => c.id == data.idArticle)
  ) {
    $q.notify({
      type: "negative",
      message: i18n.t("import.errors.previewFailed", [data.title]),
    });
  }
}
</script>
