import type { AxiosResponse } from "axios";
import type { CrudServiceInstance } from "@/types/crud";
import { ArticleDto, ArticleSearch, SearchResult } from "@/types/api";
import { CrudService } from "@/services/CrudService";
import { AxiosRequest } from "@/services/ElcanoAPI";
import { SearchServiceInstance } from "@/types/search";
import { downcastArticleDto } from "@/helpers/article";

/**
 * Service de recherche.
 */
class SearchService extends AxiosRequest implements SearchServiceInstance {
  crudService: CrudServiceInstance<ArticleDto>;

  /**
   * Constructeur de la class Article service
   */
  constructor(public rootPath: string) {
    super();
    this.crudService = new CrudService<ArticleDto>(rootPath);
  }

  /**
   * Recherche d'article
   * @param param - Paramètre de recherche
   */
  searchArticle(param: ArticleSearch[]) {
    return this.axiosInstance
      .post(`${this.rootPath}/article`, param)
      .then((response: AxiosResponse<SearchResult<ArticleDto>>) => {
        response.data.data = response.data.data.map(downcastArticleDto);
        return response.data;
      })
      .catch((reason) => {
        if (reason.response.status === 404) {
          return Promise.resolve({
            currentPage: 1,
            totalResult: 0,
            totalPage: 0,
            data: [],
          });
        } else {
          return this.handleError(reason);
        }
      });
  }

  /**
   * Récupère les traductions de l'utilisateur.
   */
  myTranslations() {
    return this.makeSearch("myTranslations");
  }

  /**
   * Récupère les traductions terminées de l'utilisateur.
   */
  myLastestCompletedTranslations() {
    return this.makeSearch("myLatestCompletedTranslations");
  }

  /**
   * Récupère les traductions de l'utilisateur à relire.
   */
  countMyTranslationsToReview(publication: string) {
    return this.makeSearchTyped<number>(
      "countMyTranslationsToReview",
      publication
    );
  }

  /**
   * Récupère les traductions de l'utilisateur à relire.
   */
  myTranslationsToReview(publication: string) {
    return this.makeSearch("myTranslationsToReview", publication);
  }

  /**
   * Récupère les traductions de l'utilisateur.
   */
  myReviews(publication: string) {
    return this.makeSearch("myReviews", publication);
  }

  /**
   * Récupère les corrections de l'utilisateur en tant qu'éditeur.
   */
  myCorrectionsAsEditor() {
    return this.makeSearch("myCorrectionsAsEditor");
  }

  /**
   * Récupère les article en attente de correction éditeur.
   */
  articlesAwaitingEditorCorrection() {
    return this.makeSearch("articlesAwaitingEditorCorrection");
  }

  /**
   * Récupère les article en correction éditeur.
   */
  articlesInEditorCorrection() {
    return this.makeSearch("articlesInEditorCorrection");
  }

  /**
   * Récupère les corrections de l'utilisateur en tant qu'auteur.
   */
  myCorrectionsAsAuthor(publication: string) {
    return this.makeSearch("myCorrectionsAsAuthor", publication);
  }

  /**
   * Récupère les articles en erreur (importation/invalidation)
   */
  myArticlesInErrorEditor() {
    return this.makeSearch("myArticlesInErrorEditor");
  }

  /**
   * Récupère les longs formats en cours.
   */
  longFormatArticles(publication: string) {
    return this.makeSearch("longFormatArticles", publication);
  }

  /**
   * Récupère les traductions pour la prochaine édition à relire.
   */
  translationsForNextEditionToReview(publication: string) {
    return this.makeSearch("translationsForNextEditionToReview", publication);
  }

  /**
   * Récupère les articles en ligne mais pas en status importé
   */
  myOnlineArticlesNotInImportedStatus() {
    return this.makeSearch("myOnlineArticlesNotInImportedStatus");
  }

  /**
   * Récupère les articles dont je suis l'auteur et le montant de la pige n'est pas renseigné.
   */
  myFreelancersWithoutAmount(publication: string) {
    return this.makeSearch("myFreelancersWithoutAmount", publication);
  }

  /**
   * Récupère les articles dont le montant de la pige n'est pas renseigné.
   */
  freelancersWithoutAmount() {
    return this.makeSearch("freelancersWithoutAmount");
  }

  /**
   * Récupère les articles de l'utilisateur.
   */
  myArticles(publication: string) {
    return this.makeSearch("myArticles", publication);
  }

  /**
   * Récupère les articles de l'utilisateur.
   */
  myOnlineArticles(publication: string) {
    return this.makeSearch("myOnlineArticles", publication);
  }

  /**
   * Récupère les articles long de l'utilisateur.
   */
  myNextLongFormat(publication: string) {
    return this.makeSearch("myNextLongFormat", publication);
  }

  /**
   * Récupère les articles publiés sans enrichissements.
   */
  articlesPublishedWithoutInterests() {
    return this.makeSearch("articlesPublishedWithoutInterests");
  }

  /**
   * Récupère les relectures de traduction de l'utilisateur en tant que chef de rubrique.
   */
  myTranslationsToReviewAsSectionEditor(publication: string) {
    return this.makeSearch(
      "myTranslationsToReviewAsSectionEditor",
      publication
    );
  }

  /**
   * Refacto pour recuperer les différents articles de l'utilisateur courant.
   */
  makeSearch(path: string, publication?: string) {
    return this.makeSearchTyped<ArticleDto[]>(path, publication).then(
      (response: ArticleDto[]) => {
        return response.map(downcastArticleDto);
      }
    );
  }

  /**
   * Refacto pour recuperer les différents articles de l'utilisateur courant.
   */
  makeSearchTyped<T>(path: string, publication?: string) {
    return this.axiosInstance
      .post(`${this.rootPath}/${path}?publication=${publication}`)
      .then((response: AxiosResponse<T>) => {
        return response.data;
      })
      .catch(this.handleError);
  }

  /**
   * Effectue une recherche a l'aide d'un objet de recherche simplifié
   * @param param parametre de recherche
   * @returns resultat de la recherche
   */
  simpleSearch(param: Partial<ArticleSearch>) {
    const p = Object.assign(
      {
        page: 1,
        nbByPage: 20,
        idWorkflow: [],
        idContentWorkflow: [],
        idBackOffice: [],
        publications: [],
        countries: [],
        interests: [],
        cms: [],
        sections: [],
        formats: [],
        formatsExcluded: [],
        contributions: [],
        states: [],
        statesAdvanced: [],
        texts: [],
        onlyOnTitleAndStandfirst: false,
        fridge: false,
        statesExcluded: [],
      },
      param
    );
    return this.searchArticle([p]);
  }
}

export const searchService = new SearchService("search");
