import type { ArticleSettingState } from "@/types/articleSetting";
import { defineStore } from "pinia";
import { articleSettingService } from "@/services/ArticleSettingService";
import { ArticleSettingDto, SettingsType } from "@/types/api";
import { i18n } from "@/i18n";
import { contributionService } from "@/services/ContributionService";

export const useArticleSettingStore = defineStore({
  id: "articleSetting",
  state: (): ArticleSettingState => ({
    articleSettings: [],
    yearsLockedList: [],
  }),
  actions: {
    filterArtSettingsByType(type: SettingsType) {
      const artSettingsFiltered = this.articleSettings.filter(
        (p) => p.idType === type
      );
      return artSettingsFiltered ? artSettingsFiltered : this.articleSettings;
    },
    async fetchArticleSettings() {
      this.articleSettings = await articleSettingService.getList();
    },
    async findArticleSetting(id: number): Promise<ArticleSettingDto> {
      const articleSetting = this.articleSettings.find((f) => f.id === id);
      if (articleSetting) return articleSetting;
      else throw new Error(i18n.t("articleSetting.errors.notFound"));
    },
    async addArticleSetting(newArticleSetting: ArticleSettingDto) {
      try {
        const articleSetting =
          await articleSettingService.insert(newArticleSetting);
        this.articleSettings.push(articleSetting);
      } catch (error) {
        console.error(error);
        throw new Error(i18n.t("articleSetting.errors.create"));
      }
    },
    async updateArticleSetting(articleSetting: ArticleSettingDto) {
      try {
        const articleSettingUpdated =
          await articleSettingService.update(articleSetting);
        const articleSettingIndex = this.articleSettings.findIndex(
          (f) => f.id === articleSetting.id
        );
        this.articleSettings[articleSettingIndex] = articleSettingUpdated;
      } catch (error) {
        console.error(error);
        throw new Error(i18n.t("articleSetting.errors.update"));
      }
    },
    async deleteArticleSetting(id: number) {
      try {
        await articleSettingService.delete(id);
        this.articleSettings = this.articleSettings.filter((f) => f.id !== id);
      } catch (error) {
        console.error(error);
        throw new Error(i18n.t("articleSetting.errors.delete"));
      }
    },
    async fetchYearsLockedList() {
      this.yearsLockedList = await contributionService.yearsLocked();
    },
  },
});
